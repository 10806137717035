import { UseCase } from "../../core/helpers/use-case";
import { UserRepository } from "../repository/user-repository";
import { User } from "../models/user";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { Injectable } from "@angular/core";
import { GetUserParams } from "@domain/models/getUser-params";

@Injectable({
  providedIn: "root",
})
export class GetUserDataUsecase implements UseCase<User, GetUserParams> {
  constructor(private _userRepository: UserRepository) {}

  async execute(params: GetUserParams): Promise<Either<Failure, User>> {
    return await this._userRepository.getUserData(params);
  }
}
